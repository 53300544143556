.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fbfbfb;
  overflow: hidden;
}

.box {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  
}

.scroll-arrow {
  z-index: 999;
  position: absolute;
  bottom: 2vw;
  height: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.scroll-arrow svg {
  fill: black;
  width: 2.5vw;
  animation: scroll-anim 2s infinite;
}

.scroll-arrow h4 {
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 300;
  font-size: 1.4vw;
  margin: 0;
  margin-left: 0.5vw;
  margin-bottom: 0.5vw;
  text-align: center;
  user-select: none;
}

.not-scroll-arrow {
  display: none;
}

@keyframes scroll-anim {
  0% {
    transform: translate(0);
  }
  50% {
    transform: translate(0, 1vw);
  }
  100% {
    transform: translate(0);
  }
}

.Cont {
  display: flex;
  justify-content: center;
  position: relative;
}

.name {
  position: fixed;
  top: 2vw;
  z-index: 999;
  color: transparent;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #3a3a3a;
}

.name h1 {
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 400;
  font-size: 3vw;

  text-transform: uppercase;
  margin: 0;
  user-select: none;
  letter-spacing: 5px;
}

.icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.icons a {
  width: 2vw;
  margin: 0 .6vw;
}

.icons a svg {
  width: 100%;
}

.name .art {
  font-family: "Cormorant Garamond", sans-serif;
  font-weight: 300;
  font-size: 1.3vw;
  color: #3a3a3a;
  text-transform: uppercase;
  margin: 0;
  user-select: none;
  letter-spacing: 7px;
}

@media (max-width : 600px) {
  .box{
    flex-direction: column;
    margin-top: 15vh;
    height: auto;
  }
  .name h1{
    font-size: 5vh;
  }
  .name .art{
    font-size: 2.2vh;
  }
  .icons{
    width: 100%;
    top: 4vh;
    display: flex;
    justify-content: space-between;
  }
  .icons a{
    width: 4vh;
    margin: 0 2vh;
  }

  .scroll-arrow{
    display: none;
  }
}

.Project_63-Project-Page-Cont {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.Project_63-Project-Page-Cont .hero-section {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_63-Project-Page-Cont .hero-section .icons {
  position: absolute;
  width: 10vw;
  z-index: 999;
  display: flex;
  right: 0;
  top: 3vw;
}

.Project_63-Project-Page-Cont .images-cont .icons a {
  width: 2vw;
  margin: 0 0.6vw;
}

.Project_63-Project-Page-Cont .images-cont .icons a svg {
  width: 100%;
}

.Project_63-Project-Page-Cont .hero-section .hero-video {
  width: 75vw !important;
  height: 42.2vw !important;
}

.Project_63-Project-Page-Cont .images-cont {
  width: 100%;
  height: auto;
  margin-top: 8vw;
}

.Project_63-Project-Page-Cont .images-cont .image-row {
  width: 100%;
  height: 100vh;
  display: flex;
}

.Project_63-Project-Page-Cont .images-cont .image-row .image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Project_63-Project-Page-Cont .images-cont .image-row .image img {
  width: 35vw;
}

.Project_63-Project-Page-Cont .images-cont .image-row .no-image {
  height: 100%;
  width: 50%;
}

.Project_63-Project-Page-Cont .credits-cont {
  width: 90%;
  height: 80vh;
  margin-top: 5vw;
  display: flex;
  border-top: 1px solid gray;
}

.Project_63-Project-Page-Cont .credits-cont .cridits-title {
  height: 100%;
  width: 50%;
  position: relative;
}

.Project_63-Project-Page-Cont .credits-cont .cridits-title span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 2vw;
  text-transform: uppercase;
  letter-spacing: 1vw;
  position: absolute;
  top: 3vw;
}
.Project_63-Project-Page-Cont .credits-cont .description {
  height: 100%;
  width: 50%;
  padding-top: 1vw;
  display: flex;
  flex-direction: column;
}
.Project_63-Project-Page-Cont .credits-cont .description .description-row {
  width: 100%;
  height: 6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.Project_63-Project-Page-Cont .credits-cont .description .description-row span {
  font-family: "Cormorant Garamond", sans-serif;
  font-size: 1.5vw;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.Project_63-Project-Page-Cont
  .credits-cont
  .description
  .description-row
  #large-title {
  font-size: 1.2vw;
}

@media (max-width: 1030px) {
  .Project_63-Project-Page-Cont .hero-section .hero-video {
    width: 127.5vh !important;
    height: 71.74vh !important;
  }
  .Project_63-Project-Page-Cont .images-cont .image-row .no-image {
    display: none;
  }
  .Project_63-Project-Page-Cont .images-cont .image-row .image {
    width: 100%;
  }
  .Project_63-Project-Page-Cont .images-cont .image-row .image img {
    width: 65vh;
  }
  .Project_63-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
}

@media (max-width: 900px) {
  .Project_63-Project-Page-Cont .credits-cont {
    flex-direction: column;
    align-items: center;
  }
  .Project_63-Project-Page-Cont .credits-cont .cridits-title {
    height: auto;

    display: flex;
    justify-content: center;
    width: 100%;
  }
  .Project_63-Project-Page-Cont .credits-cont .description {
    height: 80%;
    margin-top: 10vh;
    width: 100%;
  }
  .Project_63-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 3vh;
  }
  .Project_63-Project-Page-Cont .credits-cont .cridits-title span {
    position: relative;
    font-size: 5vh;
  }
  .Project_63-Project-Page-Cont .hero-section .icons a {
    width: 6vh;
    margin: 0 1vh;
  }
}

@media (max-width: 770px) {
  .Project_63-Project-Page-Cont .hero-section .icons {
    width: 16vh;
  }
}
@media (max-width: 600px) {
  .Project_63-Project-Page-Cont .hero-section .icons {
    width: 15vh;
    top: 6vh;
    right: 3vh;
  }
  .Project_63-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_63-Project-Page-Cont .credits-cont .description .description-row {
    height: 5vh;
  }
  .Project_63-Project-Page-Cont .credits-cont {
    width: 100%;
  }
}
@media (max-width: 420px) {
  .Project_63-Project-Page-Cont {
    width: 110%;
  }
  .Project_63-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    span {
    font-size: 2vh;
  }
  .Project_63-Project-Page-Cont .credits-cont .description .description-row {
    height: 5vh;
  }
  .Project_63-Project-Page-Cont
    .credits-cont
    .description
    .description-row
    #large-title {
    font-size: 1.2vh;
  }
}
